<template>
    <div>
        <div class="d-flex justify-content-center align-items-center" v-if="loading" style="height: 90vh">
            <b-spinner variant="primary" label="Text Centered" />
        </div>
        <div v-else>
            <b-card title="Таблица запросов на заказ">
                <b-table 
                striped
                small
                id="myTables"
                bordered
                selectable
                selected-variant="primary"
                select-mode="single"
                responsive="sm"
                :items="orders"
                :fields="fields"
                head-row-variant="secondary"
                >
                <template #cell(checkbox)="row">
                    <b-form-checkbox
                    v-model="row.detailsShowing"
                    plain
                    class="vs-checkbox-con"
                    @change="row.toggleDetails"
                    >
                    </b-form-checkbox>
                </template>
                <template #cell(order_id)="data">
                    <div @click="getOrderHistory(data)">
                    <div style="color: blue">
                        {{ data.item.order_id }}
                    </div>
                    </div>
                </template> 
                </b-table>
            </b-card>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            fields: [
                { key: 'checkbox', label: '', thStyle: {width: '30px'} },
                { key: 'order_id', label: 'Id заказа', thStyle: {width: '60px'} },
                { key: 'created_at', label: 'Создан', thStyle: {width: '30px'} },
                { key: 'count', label: 'Количество запросов', thStyle: {width: '30px'} },
            ],
            orders: [],
            loading: false
        }
    },
    methods: {
        getOrderRequests(){
            this.loading = true
            this.$http
            .get('dashboard-order-requests')
            .then(res => {
                this.orders = res.data
                this.loading = false
                this.$store.commit('REFRESH_DATA', false)
            })
            .catch((err)=> {
                this.loading = false
            })
        },
        getOrderHistory(data){
            this.$store.commit('draggableTab/ADD_TAB',['История ' + data.item.order_id, `/order/order-history`, data.item.order_id  ])
            this.$router.push({name: 'orderHistory', query:{filter: data.item.order_id}})
        },
    },
    computed: {
      fetchingNewData() {
        return this.$store.state.refresh.fetchingNewData
      }
    },
    watch: {
      fetchingNewData(val) {
        if (val) {
          this.loading = true
          this.getOrderRequests()
        }
      }
    },
    mounted(){
        this.getOrderRequests()
    }
}
</script>